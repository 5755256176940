import Link from "app/components/i18n/Link";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";

const RequestDemoButton = ({
  id = "cta_requestDemo",
  additionalClassNames,
  textClassNames,
}) => (
  <Link
    id={id}
    to="/request-demo"
    className={classNames(
      "whitespace-nowrap rounded-full text-center font-bold text-primary",
      additionalClassNames
    )}
  >
    <span className={textClassNames}>
      <Trans>Request Demo</Trans>
    </span>
  </Link>
);

export default RequestDemoButton;

RequestDemoButton.propTypes = {
  id: PropTypes.string,
  additionalClassNames: PropTypes.string,
  textClassNames: PropTypes.string,
};
